import React from "react"
import Logo from "src/components/Logo/Logo"

import "./CardAdvantages.scss"

import { useTranslation } from "react-i18next"

const CardAdvantages = ({ country, hotLine }) => {
  const { t } = useTranslation()

  return (
    <section className="card-advantages">
      <Logo type="white" country={country} />

      <h3>{t("cardAdvantages.title")}</h3>

      <div className="buttons-wrapper">
        <a className="button blue" href={hotLine.tel}>
          {t("cardAdvantages.linkTitle")}
        </a>
      </div>
    </section>
  )
}

export default CardAdvantages
